@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700;800;900&family=Open+Sans:wght@400;600;700&display=swap?family=Outfit&display=swap");

.ff_opne {
  font-family: "Open Sans", sans-serif;
}

.ff_mont {
  font-family: "Montserrat", sans-serif;
}
.ff_outfit {
  font-family: "Outfit", sans-serif;
}

.del {
  text-decoration: line-through !important;
}

body,
html {
  background-color: rgba(0, 14, 14, 1) !important;
  overflow-x: hidden !important;
  scroll-behavior: smooth;
}

.position-s {
  position: sticky !important;
  top: 0px;
}

* {
  padding: 0;
  margin: 0;
  text-decoration: none !important;
  /* list-style-type: none; */
  box-sizing: border-box;
}

ul {
  list-style-type: none;
}
:root {
  --font-xs: 16px;
  --font-sm: 20px;
  --font-md: 22px;
  --font-lg: 24px;
  --font-xl: 32px;
  --font-xxl: 48px;
  --font-3xl: 100px;
  --color-gray1: #333333;
  --color-gray2: #4f4f4f;
  --color-gray3: #828282;
  --color-light_gray: #bdbdbd;
  --color-custom_gray: #595959;
  --bgcolor-light_orange: #e2a683;
  --bgcolor-green: #001917;
  --color-black: #000000;
  --color-dark-orange: #c37a4f;
}

.fs_16 {
  font-size: var(--font-xs);
}

.fs_20 {
  font-size: var(--font-sm);
}

.fs_22 {
  font-size: var(--font-md);
}

.fs_24 {
  font-size: var(--font-lg);
}

.fs_32 {
  font-size: var(--font-xl);
}

.fs_48 {
  font-size: var(--font-xxl);
}

.fs_100 {
  font-size: var(--font-3xl);
}

.color_gray1 {
  color: var(--color-gray1);
}

.color_gray2 {
  color: var(--color-gray2);
}

.color_gray3 {
  color: var(--color-gray3);
}

.color_light_gray {
  color: var(--color-light_gray);
}

.color_custom_gray {
  color: var(--color-custom_gray);
}

.color_black {
  color: var(--color-black) !important;
}
.color_dark_orange {
  color: var(--color-dark-orange);
}

.bg_light_orange {
  background-color: var(--bgcolor-light_orange);
}

.bg_green {
  background-color: var(--bgcolor-green);
}

.cursor-pointer {
  cursor: pointer !important;
}

.heroContainer {
  position: relative;
}

.navbar {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  z-index: 40;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.Navbar {
  position: fixed;
  width: 100vw;
  z-index: 40;
}

.Navbar-1 {
  position: fixed;
  width: 100vw;
  z-index: 40;
  color: black !important;
  background-color: #0e0f0fc9;
  filter: blur(90%);
}

.countryContainer {
  display: flex;
  min-height: 100vh;
  width: 100%;
}

.nav-List {
  display: flex;
  align-items: center !important;
  list-style-type: none;
  gap: 30px;
}

/* .imgConatiner {
  width: 20%;
} */
/* .imgConatiner2 {
  width: 628px;
  height: 100vh;
} */

.Country-text {
  color: #faf9f9a1;
  font-size: calc(3.375rem + 1.5vw);
  font-weight: 900;
  letter-spacing: 0;
  line-height: 0px;
  margin-bottom: 22px;
  transform: rotate(-180deg);
  writing-mode: vertical-rl;
}

.btn-w {
  width: 100px;
}

.PhoneInputInput {
  border: 0 !important;
}

.PhoneInputCountryIcon--border {
  background-color: unset !important;
  box-shadow: unset !important;
}

/* .blurbtn{
  filter: blur(8px);
  -webkit-filter: blur(8px);
  color: #000000 !important;
} */

.iimg {
  object-fit: cover;
  object-position: center;
}

.loginBtn {
  background-color: transparent;
  border-radius: 25px !important;
  padding: 8px 24px !important;
  border: 1px solid #e2a683;
  color: #e2a683;
  transition: all 400ms ease-in-out;
}

.loginBtn:hover {
  border-radius: 25px !important;
  padding: 8px 24px !important;
  background: linear-gradient(148.93deg, #e2a683 42.16%, #c47a4f 86.96%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.01));
  color: white !important;
}

.signup {
  border: 2px solid transparent !important;
  border-radius: 25px !important;
  padding: 8px 24px !important;
  background: linear-gradient(148.93deg, #e2a683 42.16%, #e2a683 86.96%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.01));
  color: white !important;
  transition: all 400ms ease-in-out;
}

.signup:hover {
  background: transparent !important;
  color: #e2a683 !important;
  border-radius: 25px !important;
  padding: 8px 24px !important;
  border: 2px solid #c47a4f !important;
}

.activeLink {
  color: white !important;
}

.navItem {
  color: rgba(255, 255, 255, 0.804);
}

.navItem:hover {
  color: white !important;
  border-bottom: 2px solid white;
  transition: 2s ease-in;
}

.colorChange {
  background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 28, 29, 0.74) 100%
    ),
    linear-gradient(0deg, rgba(1, 25, 26, 0.76), rgba(0, 8, 8, 0.76));
}

.overlay {
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: 10;
  top: 0;
  background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 28, 29, 0.74) 100%
    ),
    linear-gradient(0deg, rgba(0, 187, 194, 0.76), rgba(0, 187, 194, 0.76));
}

.backgroundImageContainer {
  width: 100vw;
  height: 85vh;
  background-image: url("./Assets/png/hero\ _image.png");
  background-size: cover;
  background-attachment: fixed;
}

.backgroundImage {
  width: 100%;
  object-fit: cover;
  height: 100%;
  /* position: fixed; */
}

.backgroundtreeConatiner {
  height: 100vh;
  position: absolute;
  z-index: 10;
  bottom: 0%;
}

.custom_width {
  max-width: 1100px;
}

.text_position {
  left: 50%;
  transform: translateX(-50%);
  top: -5px !important;
  width: 100%;
}

.z_index0 {
  z-index: -34;
}

.z_index33 {
  z-index: 33;
  bottom: -2px;
  width: 100% !important;
}

.z_3 {
  z-index: 31 !important;
}

.z_index34 {
  z-index: 34;
}

.btm-20 {
  bottom: 10%;
  right: 5%;
  color: white !important;
  z-index: 123456 !important;
}

.it_logo_positon {
  top: 13px;
  left: 14px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotating-image {
  animation: rotate 10s linear infinite;
}

.tryout_btn {
  border: 1px solid #c47a4f;
  background-color: transparent;
  color: #c47a4f;
  padding: 12px 24px;
  border-radius: 40px;
}

/* .backgroundtree {
  width: 100vw;
  height: 100vh;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: 0px 13rem;
  background-size: cover;
  background-image: url("./Assets/png/Group.png");
} */

.backgroundPhoneConatiner {
  position: absolute;
  z-index: 10;
  bottom: 22%;
  left: 50%;
  transform: translate(-50%) !important;
  width: 80vw;
}

.backgroundPhone {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.backgroundBottomTress {
  position: absolute;
  z-index: 10;
  bottom: -2%;
  width: 100vw;
}

.backgroundBottomTrees {
  width: 100%;
  object-fit: cover;
  height: 100%;
  z-index: 30 !important;
}

.bt {
  z-index: 30 !important;
}

.train {
  position: absolute;
  color: white;
  bottom: 10%;
  right: -37%;
}

.box {
  height: 100vh;
  color: royalblue;
}

.heroText {
  position: absolute;
  top: 20%;
  left: 50%;
  color: white;
  transform: translate(-50%);
  z-index: 10;
  text-wrap: nowrap;
  font-family: "Montserrat", sans-serif;
}

.hextHeading {
  font-size: 6rem;
}

.subheading {
  text-align: center;
  font-size: 1.5rem;
  font-family: "Open sans";
  margin-left: 32px;
}

.maintitleContainer {
  position: relative;
}

.plane1 {
  position: absolute;
  top: -33%;
  right: -5%;
  z-index: 40;
}

.plane2 {
  position: absolute;
  top: 55%;
  left: -57%;
}

.heroLogo {
  width: 12rem !important;
  margin: -80px auto;
  position: relative;
  z-index: 40;
}

.serachboxContainer {
  position: relative;
  z-index: 30;
  /* margin: 120px auto; */
  width: 333px;
  border-radius: 50px;
  overflow: hidden;
  background-color: #60716f73;
  border: 1px solid rgba(183, 205, 203, 1);
  display: flex;
}

.searchInput {
  padding: 12px 26px !important;
  width: 300px;
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
}

.our_view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px;
  color: white;
  gap: 40px;
  position: relative;
  width: 100vw;
  height: 305px;
  background: linear-gradient(
    180deg,
    #000e0d 9.85%,
    #052728 53.37%,
    #000e0d 95.82%
  );
}

.our_view > p {
  width: 950px;
  text-align: center;
}

.feature {
  position: relative;
}

.vector {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 6rem;
  width: 81.3% !important;
  height: 100% !important;
}

.phoneContainer {
  width: 80vw;
  margin-top: 7rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  /* gap: 3.5rem; */
}

.rounded-lg {
  border-radius: 38px !important;
}

.modal-90w {
  min-width: 90vw;
  overflow: hidden;
}

.modal-body {
  padding: 3px;
  overflow: hidden;
}

.card_image {
  object-fit: cover;
  border-radius: 100%;
}

.CountryModalImg {
  /* width: 89vw; */
  height: 35vh;
  border-top-right-radius: 33px !important;
  border-top-left-radius: 33px !important;
  overflow: hidden;
}

.CountryModalImg > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.travel_app_container {
  margin-top: -123px;
  position: relative;
  z-index: 33;
}

.costom_width_90 {
  max-width: 90vw !important;
}

.text-shadow {
  text-shadow: 2px 9px 7px black;
}

.min-h-60 {
  min-height: 60vh;
}

.scale2:hover {
  transform: scale(1.02, 1.05);
}

.hero {
  min-height: 60vh;
  height: 100%;
  background-image: url("../src/Assets/png/bgimgeadd.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.black-shade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60vh;
  background: radial-gradient(rgba(0, 0, 0, 0.305), rgba(0, 0, 0, 0.47));
}

.blue_bg {
  background: #01a2dd;
}

.blue_text {
  color: #01a2dd;
}

.text_grey {
  color: #898989;
}

.max-h-80 {
  max-height: 80vh !important;
}

.min-h-80 {
  min-height: 80vh !important;
}

.phone {
  position: relative;
  z-index: 10;
  border: 1px solid rgba(183, 205, 203, 1);
  padding: 0px 4rem;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.504);
  filter: blur(42%);
}

.botomGrass {
  position: absolute;
  bottom: -0.7%;
  width: 100vw;
}

.mphone {
  width: 100%;
  height: 500px !important;
  object-fit: contain;
}

.iphone {
  /* width: 340px; */
  margin: 0 auto !important;
}

.first {
  position: relative;
  top: -55px;
}

.FooterImage {
  height: 60vh;
  width: 100vw;
}

.FooterImage > img {
  object-fit: cover !important;
}

.footerUperContainer {
  position: relative;
}

.footerOverlay {
  position: absolute;
  height: 60vh;
  width: 100vw;
  background-color: #000e0d4f;
}

.text-box {
  height: 60vh;
  position: relative;
  left: 20%;
}

.imge {
  object-fit: cover;
}

.footerUperConatinerData {
  position: absolute;
  z-index: 20;
  top: 0;
}

.phoneImage {
  position: absolute;
  height: 72vh;
  right: -30%;
  top: -28%;
}

.sub {
  font-weight: 300;
}

.cardc {
  position: relative;
  display: flex;
  align-items: end;
  justify-content: center;
}

.cardc > .text {
  font-family: "Open Sans";
  font-weight: 900;
  font-size: 2.2rem;
  position: absolute;
  bottom: 10%;
  text-align: center;
  color: rgba(255, 255, 255, 0.685);
}

.tst {
  font-weight: 100;
}

.footer {
  height: 40vh;
  width: 100vw;
}

.divder {
  height: 220px;
  width: 1px;
  border: 1px solid #ffffff;
  opacity: 50%;
  left: -40px;
}

.footerlogoContainer {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  overflow: hidden;
}

.footerlogo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* -------------------------trendingCard------------------------- */
.opacity_50 {
  opacity: 50%;
}

.custom_container {
  max-width: 1280px !important;
  margin: 0 auto !important;
  padding: 0 12px !important;
}

.navmanu {
  width: 25px;
  height: 25px;
}

.dotted ul {
  list-style-type: disc !important;
}

.navdote {
  border: 1px solid white;
  background-color: white;
  margin: 5px 0px;
}

.navListWrapper {
  position: fixed;
  right: 0px;
  top: 0;
  bottom: 0;
  background-color: #0e5758;
  width: 100%;
  /* max-width: 300px; */
  padding-top: 60px;
  transition: all 0.3s ease-in-out;
  z-index: 999;
}

.navList {
  list-style: none;
  padding: 0;
}

.z-5 {
  z-index: 5;
}

.navItem {
  margin-bottom: 15px;
}

.navItem a {
  text-decoration: none;
  color: white;
  font-size: 16px;
}

.cross_icon {
  position: absolute;
  top: 35px;
  left: 10px;
}

.w_100 {
  max-width: 965px;
}

.car-img {
  width: 100vw !important;
  height: 90vh !important;
  object-fit: cover;
}

.input_position {
  top: 90%;
  z-index: 39 !important;
  left: 50%;
  transform: translateX(-50%);
}

.z_index4 {
  z-index: 4;
}

.z_index5 {
  z-index: 54;
}

.aeroplan1 {
  top: -71px;
  left: 735px;
}

.aeroplan2 {
  top: 209px;
  right: -65px;
}

.aeroplan3 {
  top: 370px;
  right: 615px;
}

.aeroplan4 {
  top: 630px;
  left: -30px;
}

.aeroplan5 {
  top: 735px;
  right: 76px;
}

.aeroplan6 {
  top: 1295px;
  right: 550px;
}

.aeroplan7 {
  bottom: 0px;
  right: -10px;
}

.timeline_position {
  width: 100%;
  top: 20px !important;
  left: 50% !important;
  transform: translateX(-33%);
}

.tripimg {
  /* width: 400px; */
  height: 266.96px;
  border-radius: 20px;
  overflow: hidden;
}

.tripimg1 {
  height: 266.96px;
  border-radius: 20px;
  overflow: hidden;
}

.content_position {
  top: 20px;
  left: 20px;
}

.tripdetails {
  width: 400px;
  height: 266.96px;
}

.profile_card2 {
  box-shadow: 0px 0px 10px 0px #0000001a;
  background: #ffffff;
  border-radius: 20px;
  padding: 30px;
}

.globle_image {
  left: 50%;
  transform: translateX(-50%);
  width: 400px;
}

.cardimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.icon_position {
  bottom: 20px;
  left: 20px;
}

.line_absolute {
  bottom: 20px;
  right: 20px;
}

.card-box {
  position: relative;
}

.userPic {
  height: 60px;
  width: 60px;
  border-radius: 100%;
  overflow: hidden;
}

.userPic2 {
  height: 80px;
  width: 80px;
  border-radius: 100%;
  border: 2px solid white;
  overflow: hidden;
}

.zindx-40 {
  z-index: 40;
}

.center {
  padding: 12px;
}

slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  display: none;
  color: #222222 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-slider {
  width: 100%;
}

.slide > img {
  width: 250px;
  height: 350px;
  object-fit: cover;
}

.slideImage {
  transform: translateY(20%) !important;
  border-radius: 23px;
}

.xxyyn {
  position: absolute;
  bottom: 0px;
  margin: auto;
  padding: 0 23px;
}

.ActiveImage {
  border-radius: 23px;
  transform: scale(1) !important;
  width: 350px !important;
  height: 500px !important;
  object-fit: cover;
  position: relative;
  z-index: 20;
  left: -25px;
}

.slick-prev {
  left: -3px !important;
}

.slick-next {
  right: -2px;
}

.slick-prev:before,
.slick-prev:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #222222 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: none;
}

.closebtn {
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.text-size-md {
  font-size: 5rem;
  font-weight: 800;
  text-shadow: 6px 6px 8px black;
}

.slick-list {
  overflow-x: hidden !important;
  overflow-y: visible !important;
}

.slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* This ensures the container takes up the full height of its parent */
}

.x-index-20 {
  z-index: 20;
}

/* .slickdec {
  bottom: 12px;
  color: white;
  width: 300px;
}
.slickdec2 {
  bottom: 12px;
  color: white;
  max-width: 315px;
  width: 100%; */
/* } */

.descrip-img {
  width: 50px;
  height: 41px;
  overflow: hidden;
  border-radius: 25px;
}

.left-side {
  width: 95vw;
}

.map {
  position: relative !important;
  border-radius: 35px;
  right: 0;
  overflow: hidden;
}

.mapSearch {
  position: relative !important;
  overflow: hidden;
}

.trip_border {
  border: 1px solid #00000033;
}

/* For other screens, use 100vw */
.map-container {
  width: 95vw;
  height: 100vh;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.searchInput2 {
  position: absolute;
  top: 24px;
  z-index: 22;
  max-width: 40rem;
  width: 100%;
}

.css-3tipsp-control > input {
  background-color: rgba(255, 255, 255, 0.374) !important;
  width: 100%;
  height: 52px;
  margin: auto;
  color: black !important;
  border-radius: 25px !important;
}

.css-1jqq78o-placeholder {
  color: black !important;
}

.css-1nmdiq-menu {
  color: black !important;
}

.css-13cymwt-control {
  background-color: rgba(255, 255, 255, 0.374) !important;
  width: 100%;
  height: 52px;
  margin: auto;
  color: black !important;
  border-radius: 25px !important;
}

.z {
  position: relative;
  z-index: 98789789;
}

.mapContainer {
  width: 100vw;
  height: 80vh;
}

.image_position {
  bottom: 20px;
  left: 20px;
}

.tavell_box {
  box-shadow: 0px 0px 20px 0px #0000001a;
  padding: 10px;
  border-radius: 20px;
}

.image_profile {
  background-image: url(../src/Assets/png/profile1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px !important;
  border-radius: 20px;
}

.but {
  background: white;
  padding: 15px 50px;
  border-radius: 50px;
  border: 0px;
  box-shadow: 0px 0px 10px 0px #0000001a;
}

.but.active {
  background: #485356;
}

.active-text {
  color: white;
}

.svgactive path {
  stroke: white !important;
}

.dots_position {
  top: 20px;
  right: 20px;
}

.videoloction_position {
  bottom: 20px;
  left: 20px;
}

.my_col {
  width: 20% !important;
  padding: 0px 12px;
}

.continerImage {
  position: relative;
  height: 40vh;
}

.DesciptionContainer {
  height: 40vh;
  width: 95vw;
}

.trip-Desc {
  color: #828282;
}

.details {
  max-height: 100vh !important;
  overflow-y: scroll;
}

.details::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
}

.bg-overlay {
  background-color: #00000087 !important;
}

.clickimage {
  display: none;
}

.news_image:hover {
  transform: scale(1.02);
}

.news_image:hover .clickimage {
  display: block !important;
}

.slide-in-animation {
  transform: translateX(100%) translateY(100%);
  transition: transform 1s;
}

.bg_addfeatured {
  background-color: #001917;
}

.slider_image1 {
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
}

.tourists_box {
  box-shadow: -10px 10px 14px 0px #0000000d;
  border: 1px solid #f2f2f2;
  border-radius: 20px;
  padding: 70px 20px 20px 20px;
  min-height: 330px !important;
}

.adventure_bg {
  background-image: url(./Assets/png/adventure_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.transform {
  transform: translateY(-25%);
}

.video {
  width: 230px !important;
  height: 350px !important;
}

.ActiveVideo {
  width: 230px !important;
  height: 350px !important;
}

.video2 > video {
  height: 350px !important;
  border-radius: 23px;
  object-fit: cover !important;
}

.video2 {
  width: 230px !important;
  height: 350px !important;
  overflow: hidden;
}

.video3 {
  width: 230px !important;
  height: 350px !important;
  border-radius: 23px;
  object-fit: cover !important;
}

.video3 > video {
  height: 550px !important;
  border-radius: 23px;
  object-fit: cover !important;
}

.video > img {
  width: 230px !important;
  height: 350px !important;
  border-radius: 23px;
  object-fit: cover !important;
}

.text-wrap-pretty {
  text-wrap: pretty;
}

.editor-container {
  position: relative;
  top: -223px;
  width: 100%;
}

.mapoverloday {
  position: absolute;
  height: 12vh;
  width: 140vw;
  bottom: 0;
  box-shadow: inset -10 4px -20px 7px 14px #fdfdfd;
}

.content_position1 {
  top: 50px;
}

.image_custom_hight {
  height: 100vh !important;
}

/* .slick-dots {
  bottom: 20px !important;
} */

.allvideo_box {
  box-shadow: 0px 0px 10px 0px #00000033;
  background: #ffffff;
  padding: 16px;
  border-radius: 20px;
}

.off_btn {
  background: #27ae60;
  padding: 1px 6px 1px 6px;
  border-radius: 10px;
  border: 0;
}

.video_input {
  border: none;
  outline: none;
}

select {
  border: none;
}

.tr {
  min-height: 120vh;
  height: 120vh;
}

.slide-transition {
  transition: transform 4s cubic-bezier(0.42, 0, 0.58, 1);
  transform: translate(0%, 0%);
}

.slide-reset {
  transform: translate(100%, 100%);
}

.try_btn {
  background: linear-gradient(148.93deg, #e2a683 42.16%, #c47a4f 86.96%);
  padding: 12px 24px;
  border-radius: 40px;
  border: 0;
}

.video_content {
  top: 10px;
  left: 10px;
}

.video_card > video {
  /* height: 0 !important; */
  object-fit: contain;
  background: #000;
}

.video_card1 > video {
  /* height: 0 !important; */
  object-fit: cover !important;
}

.Explore_btn {
  background: linear-gradient(148.93deg, #e2a683 42.16%, #c47a4f 86.96%);
  padding: 12px 24px;
  border-radius: 40px;
  border: 0;
}

.bg {
  width: 100vw;
  min-height: 100vh !important;
  background-color: #4f4d4d5c;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 3;
}

.videodetail_box {
  box-shadow: 0px 0px 10px 0px #00000033;
  background: #ffffff;
  padding: 16px;
  border-radius: 20px;
}

.vi2 {
  height: 560px !important;
}

.congratulations-popup {
  box-shadow: 0px 0px 10px 0px #00000033;
  background: #ffffff;
  padding: 16px;
  border-radius: 20px;
  width: 500px;
}

.cross_icon_position {
  right: 12px;
  top: 10px;
}

.error-message {
  color: red;
}

.traveler_bg {
  background-image: url(../src/Assets/png/traveler_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.travele_box {
  box-shadow: 14.884697914123535px 14.884697914123535px 29.76939582824707px 0px
    #00000033;
  background: #ffffff;
  border-radius: 13px;
  padding: 10px;
}

.popup-section {
  box-shadow: 14.884697914123535px 14.884697914123535px 29.76939582824707px 0px
    #00000033;
  background: #ffffff;
  border-radius: 13px;
  padding: 10px;
  width: 800px;
}

.custom_pop {
  top: 41%;
  z-index: 3;
}

.transition {
  transition: all 400ms ease-in-out;
}

.no-scroll {
  overflow: hidden;
}

.train_position {
  bottom: 40px;
  right: 220px;
}

.plane_position {
  top: 40px;
  left: 200px;
}

.plan_ani {
  animation-name: plane;
  animation-timing-function: 3s;
  animation-duration: 35s;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
  top: 30%;
}

.border_1 {
  border: 2px dashed #c47a4f7a;
  width: 0.3px;
}

/* .coustom_heigt {
  height: 50px !important;
  width: 50px !important;
} */

@keyframes plane {
  0% {
    left: 1500px;
  }

  25% {
    left: 1200px;
  }

  50% {
    left: 1000px;
  }

  100% {
    left: 1400px;
  }
}

.plan_ani1 {
  animation-name: plane1;
  animation-timing-function: 3s;
  animation-duration: 35s;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
  top: 25%;
}

.scale {
  transition: all 300ms ease-in-out;
  cursor: pointer;
}

.scale:hover {
  transform: scale(1.1);
}

.container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.section {
  margin-bottom: 40px;
}

.heading {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

.text {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
}

.emailLink {
  color: #007bff;
  text-decoration: none;
}

.emailLink:hover {
  text-decoration: underline;
}

@keyframes plane1 {
  0% {
    left: 100px;
  }

  20% {
    left: 120px;
  }

  40% {
    left: 220px;
  }

  700% {
    left: 220px;
  }

  100% {
    left: 0px;
  }
}

.bg_image {
  background-image: url(../src/Assets/png/map.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.bg_grey {
  background-color: #dde3e5;
}
.z_index123 {
  z-index: 123 !important;
}

.icon_box:hover {
  transform: scale(1.1);
}

.icon_box {
  transition: all 300ms ease-in-out;
  box-shadow: 0px 0px 10px 0px #0000001a;
  border-radius: 20px;
  padding: 10px 15px;
}

.icon_position1 {
  top: 200px;
  left: 12px;
}

/* TRAVELLER COMPONENT */
.travellers-sec .slick-track {
  padding: 36px 0;
}

/* TRAVELLER BLOG PAGE */
.travel-blog-image {
  height: 500px;
}
.travel-apps-box {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 26px;
}
.travel-apps-inside-box {
  border: 1px solid black;
  border-radius: 26px;
  min-width: 150px;
  height: 100%;
}
/* .it_logo_position {
  top: 15px;
  left: 14px;
} */
@media (min-width: 1400px) {
  :root {
    --font-xs: 16px;
    --font-sm: 20px;
    --font-md: 22px;
    --font-lg: 24px;
    --font-xl: 32px;
    --font-xxl: 48px;
    --font-3xl: 100px;
  }
}

@media (min-width: 1200px) {
  :root {
    --font-xs: 15px;
    --font-sm: 20px;
    --font-md: 18px;
    --font-lg: 24px;
    --font-xl: 32px;
    --font-xxl: 48px;
    --font-3xl: 90px;
  }

  .w_100 {
    max-width: 1100px;
    margin: 0 auto;
    height: auto;
  }

  .textContainer {
    width: 26vw;
  }

  .ActiveImage {
    border-radius: 23px;
    transform: scale(1) !important;
    width: 350px !important;
    height: 500px !important;
    object-fit: cover;
    position: relative;
    z-index: 20;
    left: -25px;
  }

  .custom_trasform {
    transform: translate(130px);
  }

  .travel_box_position1 {
    top: -50px;
    left: 100px;
    z-index: 1;
    position: absolute;
  }

  .travel_box_position2 {
    top: 100px;
    left: 430px;
    z-index: 0;
    position: absolute;
  }

  .travel_box_position3 {
    top: -50px;
    left: 750px;
    z-index: 1;
    position: absolute;
  }

  .travel_box_position4 {
    top: 200px;
    left: 1000px;
    z-index: 1;
    position: absolute;
  }

  .travel_box_position5 {
    top: 350px;
    left: 150px;
    z-index: 1;
    position: absolute;
  }

  .travel_box_position6 {
    top: 350px;
    left: 750px;
    z-index: 1;
    position: absolute;
  }

  .bg_image {
    background-image: url(../src/Assets/png/map.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* height: 100vh; */
  }

  .mapoverloday {
    position: absolute;
    height: 12vh;
    width: 140vw;
    bottom: 0;
    box-shadow: inset -234px -20px 7px 14px #fdfdfd;
  }

  .coustom_heigt {
    height: 159px !important;
    width: 180px !important;
    border-radius: 11px !important;
  }
}

@media (min-width: 992px) {
  .custom_col {
    width: 20% !important;
  }
}

@media (min-width: 768px) {
  .w_full {
    width: 100% !important;
  }

  .phoneContainer {
    gap: 3.5rem;
  }
}

@media (max-width: 1199px) {
  :root {
    --font-xs: 13px;
    --font-sm: 14px;
    --font-md: 19px;
    --font-lg: 24px;
    --font-xl: 32px;
    --font-xxl: 39px;
    --font-3xl: 70px;
  }

  .text_position {
    left: 50%;
    transform: translateX(-50%);
    top: 50px;
    width: 100%;
  }

  .w_100 {
    max-width: 790px;
  }

  .ActiveImage {
    border-radius: 23px;
    transform: scale(1) !important;
    width: 350px !important;
    height: 500px !important;
    object-fit: cover;
    position: relative;
    z-index: 20;
    left: -25px;
  }

  .cardc > .text {
    font-size: 1.2rem;
  }

  .bg_image {
    background-image: url(../src/Assets/png/map.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* height: 100vh; */
  }

  .mobile_nav {
    z-index: 40;
    color: black !important;
    background-color: transparent !important;
    filter: blur(90%);
  }

  .text-size-md {
    font-size: 5rem !important;
    font-weight: 800;
    text-shadow: 6px 6px 8px black;
  }

  .mapoverloday {
    position: absolute;
    height: 12vh;
    width: 140vw;
    bottom: 0;
    box-shadow: inset -234px -20px 7px 14px #fdfdfd;
  }

  .coustom_heigt {
    height: 159px !important;
    width: 180px !important;
    border-radius: 11px !important;
  }
}

@media (max-width: 991px) {
  .phone {
    padding: 0px 0px;
  }

  .ActiveImage {
    border-radius: 23px;
    transform: scale(1) !important;
    width: 350px !important;
    height: 500px !important;
    object-fit: cover;
    position: relative;
    z-index: 20;
    left: -25px;
  }

  .my_col {
    width: 33% !important;
    padding: 0px 12px;
  }

  .popup-section {
    width: 500px;
  }

  .mobile_nav {
    z-index: 40;
    color: black !important;
    background-color: var(--bgcolor-green);
    filter: blur(90%);
  }

  .mapoverloday {
    position: absolute;
    height: 12vh;
    width: 140vw;
    bottom: 0;
    box-shadow: inset -234px -20px 7px 14px #fdfdfd;
  }
}

@media (max-width: 768px) {
  :root {
    --font-xs: 13px;
    --font-sm: 20px;
    --font-md: 22px;
    --font-lg: 18px;
    --font-xl: 32px;
    --font-xxl: 35px;
    --font-3xl: 55px;
  }

  .backgroundBottomTrees {
    height: 200px;
  }

  .backgroundPhoneConatiner {
    position: absolute;
    z-index: 10;
    bottom: 22%;
    left: 50%;
    transform: translate(-50%) !important;
    width: 80vw;
    height: 200px;
  }

  .text_position {
    left: 50%;
    transform: translateX(-50%);
    top: 70px !important;
    width: 100%;
  }

  .z_index33 {
    z-index: 33;
    bottom: -2px;
    height: 256px;
    object-fit: cover;
  }

  .our_view {
    padding: 0 !important;
  }

  .countryContainer {
    display: flex;
    flex-direction: column;
  }

  .imgConatiner {
    width: 100vw;
    height: 30vh;
  }

  .imgConatiner2 {
    width: 100vw;
    height: 50vh;
  }

  .Country-text {
    transform: rotate(-360deg);
    font-size: calc(3.375rem + 1.5vw);
    writing-mode: inherit;
    text-orientation: inherit;
    letter-spacing: 0px;
    line-height: 0px;
    margin-bottom: 22px;
    font-weight: 600;
    color: #faf9f9a1;
  }

  .ActiveImage {
    border-radius: 23px;
    transform: scale(1) !important;
    width: 350px !important;
    height: 500px !important;
    object-fit: cover;
    position: relative;
    z-index: 20;
    left: -25px;
  }

  .transform {
    transform: translateY(0%) !important;
  }

  .input_position {
    top: 60%;
    z-index: 39 !important;
    left: 50%;
    margin-top: 98px;
    transform: translateX(-50%);
  }

  .img_Pos {
    position: absolute;
    bottom: -30%;
    width: 80vw;
  }

  .congratulations-popup {
    width: 400px;
  }

  .mapoverloday {
    position: absolute;
    height: 12vh;
    width: 140vw;
    bottom: 0;
    box-shadow: inset -134px -20px 7px 14px #fdfdfd;
  }

  .coustom_heigt {
    height: 159px !important;
    width: 180px !important;
    border-radius: 11px !important;
  }
}

/* .modal-height {
  height: 70vh !important;
} */

@media (max-width: 576px) {
  :root {
    --font-xs: 12px;
    --font-sm: 17px;
    --font-md: 16px;
    --font-lg: 18px;
    --font-xl: 32px;
    --font-xxl: 30px;
    --font-3xl: 40px;
  }

  .loginBtn {
    padding: 7px 14px !important;
  }

  .signup {
    padding: 5px 14px !important;
  }

  .w_100 {
    max-width: 374px !important;
  }

  .iphone {
    width: 100% !important;
    margin: 0 auto !important;
  }

  .Country-text {
    transform: rotate(-360deg);
    font-size: calc(2.375rem + 1.5vw);
    writing-mode: inherit;
    text-orientation: inherit;
    letter-spacing: 0px;
    line-height: 0px;
    margin-bottom: 22px;
    font-weight: 600;
    color: #faf9f9a1;
  }

  .z_index33 {
    z-index: 33;
    bottom: -2px;
    height: 263px;
    object-fit: cover;
  }

  .input_position {
    top: 50%;
    z-index: 39 !important;
    left: 50%;
    margin-top: 98px;
    transform: translateX(-50%);
  }

  .ActiveImage {
    border-radius: 23px;
    transform: scale(1) !important;
    width: 350px !important;
    height: 491px !important;
    object-fit: cover;
    position: relative;
    z-index: 20;
    left: -6px;
  }

  .globle_image {
    width: 300px;
  }

  .my_col {
    width: 50% !important;
    padding: 0px 12px;
  }

  .congratulations-popup {
    width: 300px !important;
  }

  .popup-section {
    width: 300px;
  }

  .mapoverloday {
    position: absolute;
    height: 12vh;
    width: 140vw;
    bottom: 0;
    box-shadow: inset -134px -20px 7px 14px #fdfdfd;
  }

  .coustom_heigt {
    height: 150px !important;
    max-width: 200px !important;
  }
}

@media (max-width: 380px) {
  .ActiveImage {
    border-radius: 3px !important;
    transform: scale(1) !important;
    width: 200px !important;
    height: 500px !important;
    object-fit: cover !important;
    position: relative;
    z-index: 20;
  }

  .video > video {
    width: 200px !important;
    height: 350px !important;
    border-radius: 23px;
    object-fit: cover !important;
  }

  .my_col {
    width: 100% !important;
    padding: 0px 12px;
  }

  .text-size-md {
    font-size: 3rem !important;
    font-weight: 800;
    text-shadow: 6px 6px 8px black;
  }

  .mapoverloday {
    position: absolute;
    height: 12vh;
    width: 140vw;
    bottom: 0;
    box-shadow: inset -134px -20px 7px 14px #fdfdfd;
  }
  .travele_box {
    box-shadow: 14.884697914123535px 14.884697914123535px 29.76939582824707px
      0px #00000033;
    /* background: #ffffff;
    border-radius: 53px;
    overflow: hidden;
    padding: 0px !important; */
  }
}

@media screen and (min-width: 768px) {
  .map-container {
    width: 45vw;
    height: 100vh;
  }

  .left-side {
    width: 50vw;
  }

  .DesciptionContainer {
    width: 45vw;
  }

  .Trip-Container {
    min-width: 38vw;
  }

  .mapoverloday {
    position: absolute;
    height: 12vh;
    width: 140vw;
    bottom: 0;
    box-shadow: inset -134px -20px 7px 14px #fdfdfd;
  }
}
